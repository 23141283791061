import StorefrontCategoryTree from '@local-types/categoryTrees/storefrontCategoryTree'
import { getCategoryInTreeByProperty } from './urlHelperFunctions'

export const getCategorySlugFromId = (categoryId: string, tree: StorefrontCategoryTree | null): string => {
	if (!tree) return categoryId
	for (let i = 0; i < tree.children.length; i++) {
		const element = tree.children[i]
		const category = getCategoryInTreeByProperty('id', categoryId, element)
		if (category !== null) return category.slug
	}
	return categoryId
}

export const getCategoryIdFromSlug = (slug: string, tree: StorefrontCategoryTree | null): string => {
	if (!tree) return slug
	for (let i = 0; i < tree.children.length; i++) {
		const element = tree.children[i]
		const category = getCategoryInTreeByProperty('slug', slug, element)
		if (category !== null) return category.id
	}
	return slug
}
