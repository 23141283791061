import { createAction, createReducer } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppThunk } from 'store'
import { API_URL } from 'constants/paths/paths'
import StorefrontCategoryTree from '@local-types/categoryTrees/storefrontCategoryTree'

export const toggleCatNavVisibility = createAction<boolean>('toggle-cat-nav-visibility')
export const toggleMobileMenuVisibility = createAction<boolean>('toggle-mobile-menu-visibility')

interface FetchCategoryTreeFulfilled {
	categoryTree: StorefrontCategoryTree
}

export const fetchCategoryTreePending = createAction('fetch-category-trees-pending')
export const fetchCategoryTreeFulfilled = createAction<FetchCategoryTreeFulfilled>('fetch-category-trees-fulfilled')
export const fetchCategoryTreeFailed = createAction('fetch-category-trees-failed')
export const fetchCategoryTree = (id: string): AppThunk => async dispatch => {
	try {
		dispatch(fetchCategoryTreePending())
		const res = await axios(`${API_URL}/navigation/${id}`)
		dispatch(fetchCategoryTreeFulfilled({ categoryTree: res.data }))
	} catch (err) {
		dispatch(fetchCategoryTreeFailed())
	}
}
export const toggleCatNavMenu = createAction<boolean>('toggle-cat-nav-menu')
export const toggleMobileFiltersMenu = createAction<boolean>('toggle-mobile-filters-menu')
export const toggleSearchSubmitted = createAction<boolean>('toggle-search-submitted')

export interface CatNavReducer {
	catNavActive: boolean
	mobileMenuActive: boolean
	fetchCategoryTreePending: boolean
	fetchCategoryTreeFailed: boolean
	categoryTree: StorefrontCategoryTree | null
	menuOpen: boolean
	searchSubmitted: boolean
	mobileFiltersOpen: boolean
}

const initialState: CatNavReducer = {
	catNavActive: false,
	mobileMenuActive: false,
	fetchCategoryTreePending: false,
	fetchCategoryTreeFailed: false,
	categoryTree: null,
	menuOpen: false,
	searchSubmitted: false,
	mobileFiltersOpen: false,
}

const catNavReducer = createReducer(initialState, builder => {
	builder
		.addCase(toggleCatNavVisibility, (store, action) => {
			store.catNavActive = action.payload
		})
		.addCase(toggleMobileMenuVisibility, (store, action) => {
			store.mobileMenuActive = action.payload
		})
		.addCase(fetchCategoryTreePending, store => {
			store.fetchCategoryTreePending = true
			store.fetchCategoryTreeFailed = false
		})
		.addCase(fetchCategoryTreeFulfilled, (store, action) => {
			store.fetchCategoryTreeFailed = false
			store.fetchCategoryTreePending = false
			store.categoryTree = action.payload.categoryTree
		})
		.addCase(fetchCategoryTreeFailed, store => {
			store.fetchCategoryTreePending = false
			store.fetchCategoryTreeFailed = true
		})
		.addCase(toggleCatNavMenu, (store, action) => {
			if (!store.searchSubmitted) store.menuOpen = action.payload
			else store.searchSubmitted = false 
		})
		.addCase(toggleSearchSubmitted, (store, action) => {
			store.searchSubmitted = action.payload
			store.menuOpen = false
		})
		.addCase(toggleMobileFiltersMenu, (store, action) => {
			store.mobileFiltersOpen = action.payload
		})
})

export default catNavReducer
