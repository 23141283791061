import { BreadCrumbsItem } from 'interfaces/global/breadCrumbsItem'
import StorefrontCategoryTree, { StorefrontCategoryTreeItem } from '@local-types/categoryTrees/storefrontCategoryTree'
import StorefrontProduct from '@local-types/products/storefrontProduct'
import { getCategorySlugFromId } from 'helpers/filterHelpers/categoryUrlHelper'

interface CategoryItem {
	id: string
	name: string
}

// Note: recursive searching for category match and storing breadcrumbs.
const deepSearchCategory = (
	categoryId: string,
	treeItem: StorefrontCategoryTreeItem | StorefrontCategoryTree,
	categoryItems: CategoryItem[]
): CategoryItem[] => {
	const crumb = { id: treeItem.id, name: treeItem.name }
	if (categoryId === treeItem.id) {
		return [...categoryItems, crumb]
	}
	if (treeItem.children) {
		return (
			treeItem.children
				.map(elem => deepSearchCategory(categoryId, elem, [...categoryItems, crumb]))
				.find(elem => elem.length > 0) || []
		)
	}

	return []
}

const getProductDetailsBreadCrumbs = (product: StorefrontProduct, tree: StorefrontCategoryTree): BreadCrumbsItem[] => {
	// Note: Searching through all categories and attempting to find relevant Categories to display as Breadcrumbs
	const [_, ...relevantCategories] =
		product.categories.map(elem => deepSearchCategory(elem.id, tree, [])).find(elem => elem.length > 0) || []

	return [
		...relevantCategories.map((elem, index) => {
			return {
				label: elem.name,
				url: `/vorur?flokkar=${encodeURI(
					relevantCategories
						.slice(0, index + 1)
						.map(elem => getCategorySlugFromId(elem.id, tree))
						.join(';')
				)}`,
				categories: relevantCategories.slice(0, index + 1).map(elem => elem.id),
			}
		}),
		{ label: product.name, url: `/vara/${product.slug}` },
	]
}

export const getBreadCrumbs = (router: any, categoryTree: StorefrontCategoryTree): BreadCrumbsItem[] => {
	const { pathname, components, asPath } = router
	const component = components[pathname]
	if (pathname === '/vara/[pid]' && components['/vara/[pid]']) {
		const product = component?.props?.initialProps?.pageProps?.product
		if (!product) {
			return []
		}
		return getProductDetailsBreadCrumbs(product, categoryTree)
	}
	const label = component?.props?.initialProps?.pageProps?.label
	if (!label) {
		return []
	}
	return [{ label, url: asPath }]
}

export const getBreadCrumbsByCategoryId = (
	categoryId: string,
	categoryTree?: StorefrontCategoryTree
): BreadCrumbsItem[] => {
	if (!categoryId || !categoryTree) {
		return []
	}
	const [_, ...relevantCategories] = deepSearchCategory(categoryId, { ...categoryTree }, [])

	return [
		...relevantCategories.map((elem, index) => {
			return {
				label: elem.name,
				url: `/vorur?flokkar=${encodeURI(
					relevantCategories
						.slice(0, index + 1)
						.map(elem => getCategorySlugFromId(elem.id, categoryTree))
						.join(';')
				)}`,
				categories: relevantCategories.slice(0, index + 1).map(elem => elem.id),
			}
		}),
	]
}
