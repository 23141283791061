import axios from 'axios'
import { createAction, createReducer } from '@reduxjs/toolkit'
import { AppThunk } from 'store'
import { API_URL } from 'constants/paths/paths'
import { AsyncActionState } from 'interfaces/global/asyncActionState'
import StorefrontFilters from '@local-types/filters/storefrontFilters'
import { fetchProductsFilteredFulfilled, Aggregation } from './productReducer'

export type UrlParamsStatus = 'set' | 'not-set'

export interface ActiveFilters {
	catTreePath: string[]
	catTreeFilterIds: string[]
	allergenFilterIds: string[]
	search: string
	sort: string
}

export const setFiltersPage = createAction<number>('set-filters-page')
export const fetchFiltersPending = createAction('delete-user-company-relation-pending')
export const fetchFiltersFulfilled = createAction<StorefrontFilters>('delete-user-company-relation-fulfilled')
export const fetchFiltersFailed = createAction('delete-user-company-relation-failed')
export const fetchFilters = (): AppThunk => async dispatch => {
	dispatch(fetchFiltersPending())
	try {
		const res = await axios(`${API_URL}/filters`)
		dispatch(fetchFiltersFulfilled(res.data))
	} catch (err) {
		dispatch(fetchFiltersFailed())
	}
}
export const setActiveFilters = createAction<ActiveFilters>('set-active-filters')
export const clearActiveFilters = createAction<string>('clear-active-filters')
export const setUrlParamActiveFilters = createAction<ActiveFilters>('set-url-params-active-filters')
export const setUrlParamsStatus = createAction<UrlParamsStatus>('set-url-params-status')

export interface FiltersReducer {
	filters: StorefrontFilters | null
	activeFilters: ActiveFilters
	fetchFiltersStatus: AsyncActionState
	page: number
	urlParamsStatus: UrlParamsStatus
	aggregatedFilters: StorefrontFilters | null
}

const initialState = (): FiltersReducer => ({
	filters: null,
	activeFilters: {
		catTreePath: [],
		catTreeFilterIds: [],
		allergenFilterIds: [],
		search: '',
		sort: '',
	},
	fetchFiltersStatus: 'passive',
	page: 1,
	urlParamsStatus: 'not-set',
	aggregatedFilters: null,
})

const cloneObject = (obj?: Record<string, any> | null) => {
	return obj ? JSON.parse(JSON.stringify({ ...obj })) : null
}

type BucketAggregation = {
	categories: {
		buckets: Array<{
			doc_count: number
			key: string
		}>
	}
}

type DefaultAggregation = Array<Aggregation>

type PayloadAggregation = BucketAggregation | DefaultAggregation

function isBucketAggregation(payload: PayloadAggregation): payload is BucketAggregation {
	return (payload as BucketAggregation).categories !== undefined
}

const getProductAggregations = (aggregations?: PayloadAggregation): Array<Aggregation> => {
	if (!aggregations) {
		return []
	}
	if (isBucketAggregation(aggregations)) {
		return aggregations?.categories?.buckets.map(bucket => ({ id: bucket.key, count: bucket.doc_count }))
	}
	return Array.isArray(aggregations) ? aggregations : []
}

const filtersReducer = createReducer(initialState(), builder =>
	builder
		.addCase(fetchFiltersPending, store => {
			store.fetchFiltersStatus = 'pending'
		})
		.addCase(fetchFiltersFulfilled, (store, action) => {
			store.fetchFiltersStatus = 'fulfilled'
			store.filters = action.payload
		})
		.addCase(fetchFiltersFailed, store => {
			store.fetchFiltersStatus = 'failed'
		})
		.addCase(setFiltersPage, (store, action) => {
			store.page = action.payload
		})
		.addCase(setActiveFilters, (store, action) => {
			store.activeFilters = action.payload
		})
		.addCase(setUrlParamActiveFilters, (store, action) => {
			store.activeFilters = action.payload
			store.urlParamsStatus = 'set'
		})
		.addCase(setUrlParamsStatus, (store, action) => {
			store.urlParamsStatus = action.payload
		})
		.addCase(clearActiveFilters, (store, action) => {
			store.activeFilters = initialState().activeFilters
			if (action.payload) {
				store.activeFilters.search = action.payload
			}
		})
		.addCase(fetchProductsFilteredFulfilled, (store, action) => {
			const aggregatedFilters = cloneObject(store.filters)
			const payloadAggregation = getProductAggregations(action?.payload?.aggregations)

			if (aggregatedFilters) {
				for (const category of aggregatedFilters.categoryFilterTrees) {
					const newChildren = []
					for (const child of category.children) {
						if (payloadAggregation.find(elem => elem.id == child.id)) {
							newChildren.push(child)
						}
					}
					category.children = newChildren
				}
				store.aggregatedFilters = aggregatedFilters
			}
		})
)

export default filtersReducer
