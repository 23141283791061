import React, { FC, useState, useEffect, useMemo } from 'react'
import * as StyledBreadCrumb from './breadCrumbs.styles'
import HomeIcon from '../../icons/home.svg'
import ArrowLeft from '../../icons/arrow-left.svg'
import { BreadCrumbsItem } from 'interfaces/global/breadCrumbsItem'
import useFilters from 'hooks/useFilters'

import { useRouter } from 'next/router'
import { getBreadCrumbs } from '../../helpers/breadCrumbsHelper/breadCrumbsHelper'
import Link from 'next/link'
interface Props {
	items?: BreadCrumbsItem[]
	onFilteredProductListPage?: boolean
}

const BreadCrumbs: FC<Props> = ({ items = [], onFilteredProductListPage }) => {
	const { filters, activeFilters, setMainCategoryTreePath } = useFilters()
	const mainCategoryTree = filters?.mainCategoryTree
	const router = useRouter()
	const { asPath } = router

	const onBack = () => router.back()

	const setActiveFilters = (cats: string[] | undefined) => {
		if (!cats) return
		setMainCategoryTreePath(cats)
	}

	const breadCrumbsItems = useMemo(() => {
		if (!mainCategoryTree && items.length == 0) {
			return []
		}
		if (mainCategoryTree && items.length === 0) {
			return getBreadCrumbs(router, mainCategoryTree)
		}
		if (items.length > 0) {
			return items
		}
		return []
	}, [asPath, mainCategoryTree, activeFilters])

	return (
		<StyledBreadCrumb.Container className={onFilteredProductListPage ? 'onFilteredProductListPage' : ''}>
			<StyledBreadCrumb.InnerContainer>
				<StyledBreadCrumb.Wrapper>
					<Link href="/">
						<StyledBreadCrumb.FrontPageItem href="/">
							<HomeIcon />
							Forsíða
						</StyledBreadCrumb.FrontPageItem>
					</Link>
					{breadCrumbsItems.map(item => (
						<Link href={encodeURI(item.url)} key={item.label}>
							<StyledBreadCrumb.Item href={encodeURI(item.url)} onClick={() => setActiveFilters(item.categories)}>
								{item.label}
							</StyledBreadCrumb.Item>
						</Link>
					))}
				</StyledBreadCrumb.Wrapper>
				{!onFilteredProductListPage && (
					<StyledBreadCrumb.GoBack onClick={onBack}>
						<StyledBreadCrumb.IconWrapper>
							<ArrowLeft />
						</StyledBreadCrumb.IconWrapper>
						<StyledBreadCrumb.Item isLast>Til Baka</StyledBreadCrumb.Item>
					</StyledBreadCrumb.GoBack>
				)}
			</StyledBreadCrumb.InnerContainer>
		</StyledBreadCrumb.Container>
	)
}

export default BreadCrumbs
