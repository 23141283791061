import styled, { css } from 'styled-components'
import { media } from '../../constants/media/media'

export const Item = styled.a<{ isLast?: boolean }>`
	font-weight: 500;
	font-family: ${({ theme }) => theme.fonts.fontFamily};
	font-size: ${({ theme }) => theme.fonts.fontSize};
	color: ${({ theme }) => theme.colors.textDark};
	position: relative;
	margin-right: 2.5rem;
	cursor: pointer;
	&:hover {
		color: ${({ theme }) => theme.colors.primary};
	}
	&:after {
		content: '/';
		position: absolute;
		right: -1.5rem;
		color: ${({ theme }) => theme.colors.grayLight};
	}
	${({ isLast }) =>
		isLast &&
		css`
			color: ${({ theme }) => theme.colors.textColor};
			margin-right: 0;
			&:after {
				content: none;
			}
		`}
`

export const FrontPageItem = styled(Item)`
	align-items: center;
	display: flex;
	svg {
		margin-right: 1.5rem;
	}
`

export const IconWrapper = styled.div`
	transition: all 0.25s ease-in-out;
`

export const GoBack = styled.div`
	display: flex;
	align-items: center;
	${Item} {
		margin-left: 1.5rem;
		color: ${({ theme }) => theme.colors.textDark};
	}
	${IconWrapper} {
		position: relative;
	}

	&:hover {
		${IconWrapper} {
			transform: translate3d(-0.5rem, 0, 0);
		}
		${Item} {
			color: ${({ theme }) => theme.colors.primary};
		}
	}
`

export const Wrapper = styled.div`
	display: flex;
	align-items: center;

	${Item} {
		&:nth-last-of-type(1) {
			color: #4e5a68;
			margin-right: 0;
			&:after {
				content: none;
			}
		}
	}
`

export const InnerContainer = styled.div`
	max-width: 192rem;
	width: 100%;
	margin: 0 auto;
	padding: 1.5rem 3.75rem;
	display: flex;
	justify-content: space-between;
`

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
	display: none;
	width: 100%;
	${media.tablet`
    display: flex;
	`}

	&.onFilteredProductListPage {
		${InnerContainer} {
			max-width: calc(192rem - 37.5rem);
			padding-left: 3.75rem;
		}
	}
`
