import useTypedSelector from 'interfaces/useTypedSelector'
import { useDispatch } from 'react-redux'
import { setActiveFilters, clearActiveFilters } from 'reducers/filtersReducer'

// Filters are fetched in layout.tsx

const useFilters = () => {
	const dispatch = useDispatch()
	const { page, filters, activeFilters, fetchFiltersStatus, aggregatedFilters } = useTypedSelector(
		({ filters }) => filters
	)

	const setMainCategoryTreePathFromFilter = (path: string[]) => {
		dispatch(
			setActiveFilters({
				...activeFilters,
				catTreePath: path,
			})
		)
	}

	const setMainCategoryTreePath = (path: string[]) => {
		dispatch(
			setActiveFilters({
				catTreePath: path,
				catTreeFilterIds: [],
				allergenFilterIds: [],
				search: '',
				sort: '',
			})
		)
	}

	const setFilterTreeIds = (ids: string[]) => {
		dispatch(
			setActiveFilters({
				...activeFilters,
				catTreeFilterIds: ids,
			})
		)
	}

	const setAllergenFilterIds = (ids: string[]) => {
		dispatch(
			setActiveFilters({
				...activeFilters,
				allergenFilterIds: ids,
			})
		)
	}

	const setFilterSearch = (value: string) => {
		dispatch(
			setActiveFilters({
				...activeFilters,
				search: value,
			})
		)
	}
	const setSorting = (value: string) => {
		dispatch(
			setActiveFilters({
				...activeFilters,
				sort: value,
			})
		)
	}

	const clearFilters = (searchString?: string) => {
		dispatch(clearActiveFilters(searchString || ''))
	}

	return {
		page,
		filters,
		aggregatedFilters,
		activeFilters,
		loading: fetchFiltersStatus === 'pending',
		error: fetchFiltersStatus === 'failed',
		setMainCategoryTreePath,
		setMainCategoryTreePathFromFilter,
		setFilterTreeIds,
		setAllergenFilterIds,
		setFilterSearch,
		setSorting,
		clearFilters,
	}
}

export default useFilters
