import { StorefrontCategoryTreeItem } from '@local-types/categoryTrees/storefrontCategoryTree'

export const getCategoryInTreeByProperty = (
	property: 'id' | 'slug',
	key: string,
	treeItem: StorefrontCategoryTreeItem
): StorefrontCategoryTreeItem | null => {
	if (treeItem[property] === key) return treeItem
	if (treeItem.children.length > 0) {
		for (let i = 0; i < treeItem.children.length; i++) {
			const category = getCategoryInTreeByProperty(property, key, treeItem.children[i])
			if (category !== null) return category
		}
	}
	return null
}
